import React, { useEffect, useState, useCallback, useRef } from "react";
import ReactDOM from "react-dom";
import Card from "@mui/material/Card";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import { useNavigate } from "react-router-dom";
import { useDirectus } from "../../Providers/DirectusProvider";
import { useForm } from "react-hook-form";
import InfoIcon from "@mui/icons-material/Info";
import DescriptionIcon from "@mui/icons-material/Description";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Swal from "sweetalert2";
import * as xlsx from "xlsx";
import { Box, Typography, Paper } from "@mui/material";
import DialogContent from "@material-ui/core/DialogContent";

import { InputSelect } from "./InputSelect";
import Modal from "@mui/material/Modal";
import { CargaUnitaria } from "./CargaUnitaria";
import FileValidation from "./FileValidation";
import ProcesoSummary from "./ProcesoSummary";

import "./SalesForm.css";

export const AddBeneficiario = ({
    idClientesEmpresas = null,
    idSeguro = null,
}) => {
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        setFocus,
    } = useForm({ mode: "all" });
    const cargaUnitariaRef = useRef();
    const { directus } = useDirectus();
    const [planes, setPlanes] = useState([]);
    const [paises, setPaises] = useState([]);
    const [clienteEmpresas, setClienteEmpresas] = useState([]);
    const [nombrePlan, setNombrePlan] = useState("");
    const [nombreClienteEmpresas, setnombreClienteEmpresas] = useState("");
    const [fileInfo, setFileInfo] = useState(null);
    const [missingColumns, setMissingColumns] = useState([]);
    const [fileValidationError, setFileValidationError] = useState(null);
    const [archivoSeleccionadoExitoso, setArchivoSeleccionadoExitoso] =
        useState(true);

    const [openCargaUnitaria, setOpenCargaUnitaria] = useState(false);

    const [modalData, setModalData] = useState(null);

    const handleOpenCargaUnitaria = () => {
        setModalData(null);
        setOpenCargaUnitaria(true);
    };
    const handleOpenModal = (rowData) => {
        setModalData(rowData);
        setOpenCargaUnitaria(true);
    };

    const handleCloseCargaUnitaria = () => {
        console.log("cerrando modal");
        setOpenCargaUnitaria(false);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const isOpen = Boolean(anchorEl);

    const openRequisitosFile = useCallback(() => {
        Swal.fire({
            width: 1000,
            customClass: {
                title: "mas-info",
                confirmButton: "button-plan btn-principal siguiente-info",
            },
            title: "<strong> Requisitos del documento</strong>",
            html: `
                <div>
                    <p>El tipo de archivo debe ser xlxs.</p>
                    <p>El tamaño máximo es de 10MB.</p>
                    <p>El archivo debe contener las siguientes columnas con campos obligatorios:</p>
                    <table border="1" style="width:100%">
                        <tr>
                            <th>Nombres</th>
                            <th>Apellido Paterno</th>
                            <th>Apellido Materno</th>
                            <td>Fecha de Nacimiento</td>
                        </tr>
                        <tr>
                            <td>RUT</td>
                            <td>DV</td>
                            <td>Celular</td>
                            <td>Género</td>
                        </tr>
                    </table>
                </div>
                `,
            showCloseButton: true,
            focusConfirm: true,
            showCancelButton: true,
            confirmButtonText: "Descargar Plantilla",
            cancelButtonText: "Cerrar",
            cancelButtonColor: "#d33",
            preConfirm: () => {
                window.location.href =
                    "https://seguros.qa.ticmedical.cl/archivos-publicos/archivos/plantilla_asegurados_2024.xlsx";
            },
        });
    });

    const [datos, setDatos] = useState({
        file: null,
        idPlan: "",
        clienteEmp:
            idClientesEmpresas && idClientesEmpresas.length >= 1
                ? idClientesEmpresas[0].id
                : "",
        responsable: "Andrés",
        mantenedor: true,
        pais: ""
    });

    const onSubmitDatosBeneficario = (data) => enviarListadoBeneficiarios();

    const HandleInputChange = async (event) => {
        const inputName = event.target.name;
        const inputValue = event.target.value;

        if (inputName === "file" && fileInputRef.current) {
            const file = fileInputRef.current?.files[0];

            setMissingColumns([]);
            setFileValidationError(null);

            const validationMessage = FileValidation(file, true);
            if (validationMessage) {
                console.log(validationMessage);
                setFileValidationError(validationMessage);
                return;
            }
            setArchivoSeleccionadoExitoso(true);
            setDatos((datos) => ({
                ...datos,
                [inputName]: file || null,
            }));

            try {
                const data = await readFileAsync(file);
                const workbook = xlsx.read(data, { type: "array" });

                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];

                const columnNames = [];
                const rowData = [];

                for (let key in worksheet) {
                    const columnName = key.replace(/[0-9]/g, "");
                    if (!columnNames.includes(columnName)) {
                        columnNames.push(columnName);
                    }
                }

                const rowCount =
                    xlsx.utils.decode_range(worksheet["!ref"]).e.r + 1;

                let nonEmptyRowCount = 0;

                for (let col of columnNames) {
                    const cellKey = col + "1";
                    const cellValue = worksheet[cellKey]
                        ? worksheet[cellKey].v
                        : "";
                    rowData.push(cellValue);
                }

                for (let i = 1; i <= rowCount; i++) {
                    let rowHasData = false;

                    for (let col of columnNames) {
                        if (col !== "!ref") {
                            const cellKey = col + i;
                            const cellValue = worksheet[cellKey]
                                ? String(worksheet[cellKey].v) // Convierte el valor a cadena
                                : "";

                            if (
                                typeof cellValue === "string" &&
                                cellValue.trim() !== ""
                            ) {
                                rowHasData = true;
                                break;
                            }
                        }
                    }

                    if (rowHasData) {
                        nonEmptyRowCount++;
                    }
                }

                console.log("Información del archivo:", {
                    rowCount: nonEmptyRowCount,
                });
                setFileInfo({
                    rowCount: nonEmptyRowCount,
                });

                const requiredColumns = [
                    "rut",
                    "dv",
                    "nombres",
                    "apellidoPaterno",
                    "apellidoMaterno",
                    "fechaNacimiento",
                    "genero",
                    "celular",
                ];

                setMissingColumns(
                    requiredColumns.filter(
                        (col) => !rowData.some((rowCol) => rowCol.trim().toLowerCase() === col.toLowerCase())
                    )
                );

                if (missingColumns.length > 0) {
                    setArchivoSeleccionadoExitoso(false);
                }
            } catch (error) {
                setArchivoSeleccionadoExitoso(false);
                console.error("Error al leer el archivo:", error);
            }
        } else {
            if (inputName === "idPlan") {
                const selectedOption =
                    event.target.options[event.target.selectedIndex];
                setNombrePlan(selectedOption.text == 'Utilizar plan de documento' ? 'del documento,' : selectedOption.text);
            }
            if (inputName === "clienteEmp") {
                const selectedOption =
                    event.target.options[event.target.selectedIndex];
                setnombreClienteEmpresas(selectedOption.text);
            }
            setDatos((datos) => ({
                ...datos,
                [inputName]: inputValue,
            }));
        }
    };

    const readFileAsync = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(new Uint8Array(e.target.result));
            reader.onerror = (e) => reject(e);
            reader.readAsArrayBuffer(file);
        });
    };

    const forzarError = (input, error) => {
        setFocus(input);
        setError(input, { type: "custom", message: error });
    };

    const enviarListadoBeneficiarios = useCallback(async () => {
        // Declarar la variable fuera del bloque try
        console.log("submit");
        if (datos.file === null) {
            console.log("FALTAN FALTAN");
            Swal.fire({
                icon: "error",
                title: "Falta excel",
                text: "Falta seleccionar un archivo.",
            });
            return;
        }
        if (missingColumns.length > 0) {
            console.log("FALTAN FALTAN");
            Swal.fire({
                icon: "error",
                title: "Faltan datos en el excel",
                text:
                    "Faltan los siguientes campos " + missingColumns.join(", "),
            });
            return;
        }
        if (fileValidationError) {
            // Display an error message, or handle it as needed
            Swal.fire({
                icon: "error",
                title: "Error en el archivo",
                text: fileValidationError,
            });
            return; // Prevent form submission
        }

        // Verifica si idClientesEmpresas tiene datos y asigna el valor correspondiente
        const clienteEmpId =
            idClientesEmpresas && idClientesEmpresas.length > 0
                ? idClientesEmpresas[0].id
                : null;
        console.log("idClientesEmpresas[0]");
        console.log(idClientesEmpresas[0]);

        console.log("clienteEmpId");
        console.log(clienteEmpId);
        /*console.log("datos");
        console.log(datos);*/
        // Display confirmation SweetAlert with names
        let textoAlert = `¿Estás seguro que deseas crear/inhabilitar  ${
                fileInfo.rowCount - 1
            } beneficiarios en el plan ${nombrePlan} usando el cliente empresa ${
                idClientesEmpresas[0].nombre_convenio
            }?`;
        if(!nombrePlan){
            textoAlert = `¿Estás seguro que deseas crear/inhabilitar  ${
                fileInfo.rowCount - 1
            } beneficiarios usando los datos Plan y Empresa del documento?`;
        }
        const confirmationResult = await Swal.fire({
            icon: "question",
            title: "Confirmación",
            text: textoAlert,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, continuar",
            cancelButtonText: "Cancelar",
        });

        if (!confirmationResult.isConfirmed) {
            return;
        }

        const loadingSwal = Swal.fire({
            title: "Cargando beneficiarios",
            text: "Este proceso puede tardar varios minutos.",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
                Swal.showLoading();
            },
        });
        try {
            const file = fileInputRef.current.files[0];
            const formData = new FormData();
            formData.append("file", file);
            formData.append("idPlan", datos.idPlan);
            formData.append("pais", datos.pais);
            formData.append("clienteEmp",clienteEmpId);
            formData.append("responsable", datos.responsable);
            formData.append("mantenedor", datos.mantenedor);
            console.log("formData");
            console.log(formData);
            const infoCargatura = await directus.transport.post(
                "/crearBeneficiario/create/file/",
                formData
            );
            console.log("infoCargatura:", infoCargatura);

            // Dividir los fragmentos JSON en un array
            const rawFragments = infoCargatura.raw.split("}{");

            // Analizar cada fragmento por separado
            const resumenes = rawFragments.map((fragment, index) => {
                // Agregar las llaves que puedan faltar
                const fragmentoConLlaves =
                    (index > 0 ? "{" : "") +
                    fragment +
                    (index < rawFragments.length - 1 ? "}" : "");

                // Analizar el fragmento
                return JSON.parse(fragmentoConLlaves);
            });

            // Tomar el último resumen
            const ultimoResumen = resumenes[resumenes.length - 1];

            // Renderizar el componente ProcesoSummary con el resumen
            ReactDOM.render(
                <ProcesoSummary
                    procesoSummary={ultimoResumen}
                    onOpenModal={handleOpenModal}
                />,
                document.getElementById("procesoSummaryContainer")
            );
            loadingSwal.close();
        } catch (error) {
            loadingSwal.close();
            console.error("Error al procesar la solicitud:", error);
        }
    }, [datos, directus, forzarError, setError, missingColumns]);

    useEffect(() => {
        async function getPlanes() {
            const res = await directus.items("seguro_plan").readByQuery({
                fields: ["idPlan.*"],
                filter: {
                    idSeguro: { _in: idSeguro },
                },
            });

            const  planesMapped = res.data.map(item => item.idPlan);
            console.log('TESTING 1');
            console.log(planesMapped);

            setPlanes(planesMapped);
        }

        if (idSeguro && Array.isArray(idSeguro) && idSeguro.length > 0) {
            getPlanes();
        }
    }, [directus, idSeguro]);

    // En el render
useEffect(() => {
    console.log('Planes State:', planes);
}, [planes]);



    useEffect(() => {
        async function getPaises() {
            const res = await directus.items("pais").readByQuery({
                fields: ["*"],
            });
            setPaises(res.data);
        }

        getPaises();
    }, [directus]);

    let listPaises = paises.map((p) => (
        <option key={p.idPais} value={p.idPais} sku={p.codPais}>
            {p.nombre}
        </option>
    ));
    let listPlanes = planes.map((p) => (
        <option key={p.idPlan} value={p.idPlan} sku={p.sku}>
            {p.nombre}
        </option>
    ));
    let listClienteEmpresa = idClientesEmpresas.map((c) => (
        <option key={c.id} value={c.id}>
            {c.nombre_convenio}
        </option>
    ));

    return (
        <div className="">
            <Card sx={{ minWidth: 275, padding: 1 }}>
                <ListItemText primary="Ingresar nuevos beneficiarios" />
                <Divider light />
                <form
                    onSubmit={handleSubmit(onSubmitDatosBeneficario)}
                    autoComplete="false"
                    className="formularioSale"
                >
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <label
                                className="d-flex flex-row"
                                htmlFor={"files"}
                            >
                                <span className="dot">•</span> Archivo de
                                beneficiarios
                                <div>
                                    <InfoIcon
                                        className="InfoIcon"
                                        aria-owns={
                                            isOpen
                                                ? "mouse-over-popover"
                                                : undefined
                                        }
                                        aria-haspopup="true"
                                        onMouseEnter={handlePopoverOpen}
                                        onMouseLeave={handlePopoverClose}
                                        onClick={() => openRequisitosFile()}
                                    ></InfoIcon>
                                    <Popover
                                        id="mouse-over-popover"
                                        sx={{
                                            pointerEvents: "none",
                                        }}
                                        open={isOpen}
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "left",
                                        }}
                                        onClose={handlePopoverClose}
                                        disableRestoreFocus
                                    >
                                        <Typography sx={{ p: 1 }}>
                                            Detalle de documento
                                        </Typography>
                                    </Popover>
                                </div>
                            </label>
                            <div className="d-flex flex-column">
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    name="file"
                                    onChange={HandleInputChange}
                                    accept=".xlsx"
                                />
                                {fileValidationError && (
                                    <small className="text-danger-seguro">
                                        {fileValidationError}
                                    </small>
                                )}
                            </div>

                            {fileInfo && (
                                <Paper
                                    elevation={3}
                                    style={{
                                        padding: "15px",
                                        marginTop: "15px",
                                    }}
                                >
                                    <Typography variant="h6" gutterBottom>
                                        Resumen de archivo
                                    </Typography>
                                    <Typography>
                                        <strong>
                                            Cantidad de beneficiarios:
                                        </strong>{" "}
                                        {fileInfo.rowCount - 1}
                                    </Typography>
                                    {missingColumns.length > 0 &&
                                        (console.log(
                                            "Dentro del renderizado condicional:",
                                            missingColumns
                                        ),
                                        (
                                            <Typography>
                                                <strong>
                                                    No se encontraron las
                                                    siguientes columnas en el
                                                    documento:
                                                </strong>{" "}
                                                {missingColumns.join(", ")}
                                            </Typography>
                                        ))}
                                </Paper>
                            )}
                        </Box>
                    </div>
                    {Array.isArray(idClientesEmpresas) &&
                        idClientesEmpresas.length > 1 && (
                            <>
                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                                    <Box
                                        sx={{
                                            "& > :not(style)": { m: 1 },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <InputSelect
                                            register={register}
                                            descripcion="Cliente Empresa"
                                            rules={{
                                                required: "campo obligatorio",
                                            }}
                                            id="clienteEmp"
                                            name="clienteEmp"
                                            option="Seleccione Cliente Empresa"
                                            options={listClienteEmpresa}
                                            errors={errors.clienteEmp}
                                            value={datos.clienteEmp}
                                            onChange={HandleInputChange}
                                            infoPlan={datos.clienteEmp}
                                        />
                                    </Box>
                                </div>
                            </>
                        )}

                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputSelect
                                register={register}
                                descripcion="Seleccione país"
                                rules={{}}
                                id="pais"
                                name="pais"
                                option="Utilizar país de documento"
                                options={listPaises}
                                errors={errors.pais}
                                value={datos.pais}
                                onChange={HandleInputChange}
                                infoPlan={datos.plan}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputSelect
                                register={register}
                                descripcion="Seleccione Plan"
                                rules={{}}
                                id="idPlan"
                                name="idPlan"
                                option="Utilizar plan de documento"
                                options={listPlanes}
                                errors={errors.idPlan}
                                value={datos.idPlan}
                                onChange={HandleInputChange}
                                infoPlan={datos.idPlan}
                            />
                        </Box>
                    </div>

                    <div className="col-md-12 btn-acciones d-flex flex-row">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="info"
                                startIcon={<DescriptionIcon />} // Agrega el icono de documento
                            >
                                Cargar documento
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Button
                                type="button"
                                variant="contained"
                                color="info"
                                startIcon={<PersonAddIcon />}
                                onClick={handleOpenCargaUnitaria}
                            >
                                Registrar un beneficiario
                            </Button>
                        </Box>
                    </div>
                </form>
                <div id="procesoSummaryContainer"></div>
            </Card>
            <Modal
                open={openCargaUnitaria}
                onClose={handleCloseCargaUnitaria}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableAutoFocus
            >
                <DialogContent>
                    <CargaUnitaria
                        ref={cargaUnitariaRef}
                        planes={planes}
                        paises={paises}
                        idClientesEmpresas={idClientesEmpresas}
                        onSubmitComplete={handleCloseCargaUnitaria}
                        modalData={modalData}
                        idPlanModal={datos.idPlan}
                    />
                </DialogContent>
            </Modal>
        </div>
    );
};
