import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { useDirectus } from "../../Providers/DirectusProvider";
import { InputRut } from "./InputRut";
import { InputDni } from "./InputDni";
import { InputText } from "./InputText";
import { InputEmail } from "./InputEmail";
import { InputDate } from "./InputDate";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import Moment from "moment";
import { InputSelect } from "./InputSelect";
import Switch from "@mui/material/Switch";

import "./SalesForm.css";

export const CargaUnitaria = React.forwardRef((props, ref) => {
    const { planes } = props;
    const { paises } = props;
    const { idClientesEmpresas } = props;
    const { onSubmitComplete } = props;
    const { modalData } = props;
    const { idPlanModal } = props;

    //const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset, // Método reset del react-hook-form
        setError,
        formState: { errors },
        setFocus,
        clearErrors,
        setValue,
    } = useForm({ mode: "all" });
    const { directus } = useDirectus();
    const [generos, setGeneros] = useState([]);

    const [asignarModalData, setAsignarModalData] = useState(false);
    const [tieneValorModal, setTieneValorModal] = useState(false);
    const [mostrarMensajePais, setMostrarMensajePais] = useState(false);
    const [mostrarMensajeEmpresa, setMostrarMensajeEmpresa] = useState(false);

    const [datos, setDatos] = useState({
        rut: "",
        dni: "",
        nombres: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        genero: "",
        fechaNacimiento: "",
        celular: "",
        telefono: "",
        email: "",
        email_confirmacion: "",
        direccion: "",
        nro_direccion: "",
        referencia: "",
        region: "",
        comuna: "",
        idBeneficiario: "",
        plan: "",
        titular: "",
        clienteEmp: idClientesEmpresas ? idClientesEmpresas[0].id : "",
        pais: 1,
        nacionalidad: 1,
    });

    const onSubmitDatosBeneficario = (data) => enviarFormulario();

    const HandleInputChange = (event) => {
        if (document.activeElement.name === event.target.name) {
            setDatos({
                ...datos, //una copia para no ir borrando
                [event.target.name]: event.target.value,
            });
        } else {
            datos[event.target.name] = "";
        }
    };

    const [checked, setChecked] = React.useState(false);

    const handleChangeNacionalidad = (event) => {
        setChecked(event.target.checked);
    };

    const forzarError = (input, error) => {
        //datos[input] = datos[input];
        setFocus(input);
        setError(input, { type: "custom", message: error });
    };

    const [openLoading, setOpenLoading] = React.useState(false);
    const handleCloseLoading = () => {
        setOpenLoading(false);
    };
    const handleToggleLoading = () => {
        setOpenLoading(!openLoading);
    };

    const enviarFormulario = useCallback(async () => {
        // handleToggleLoading();
        let beneficiario = false;
        console.log("LOS DATOS");
        console.log(datos);
        try {
            beneficiario = await directus.transport.post(
                "/crearBeneficiario/create/one",
                { datos }
            );
        } catch (error) {
            handleCloseLoading();
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Ocurrio un error" + error,
            });

            beneficiario = false;
        }

        if (beneficiario.raw.status == 401 || beneficiario.raw.status == 500) {
            handleCloseLoading();
        } else {
            handleCloseLoading();
            onSubmitComplete();
            Swal.fire({
                customClass: {
                    title: "titulo-venta",
                },
                title: "¡Beneficiario registrado correctamente!",
                icon: "success",
                confirmButtonText: "Aceptar",
            }).then((result) => {
                /* if (result.isConfirmed) {
                    navigate("/admin-beneficiarios", {
                        success: true,
                    });
                } else {
                    navigate("/admin-beneficiarios", {
                        success: true,
                    });
                }*/
            });
        }
    }, [datos, directus, forzarError, setError]);

    //CONSULTAR BENEFICIARIO
    useEffect(() => {
        async function getGeneros() {
            const res = await directus
                .items("genero")
                .readByQuery({ fields: ["*"] });
            setGeneros(res.data);
        }
        getGeneros();
    }, [directus]);

    useEffect(() => {
        if (!asignarModalData) {
            console.log("Modal Data:", modalData);
            const { data, razon } = modalData || {};

            // Asignar datos al formulario y a la variable datos
            if (data) {
                const generoMapping = { F: 1, M: 2, O: 3 };
                const generoFormatted =
                    data.genero === "M" ||
                    data.genero === "F" ||
                    data.genero === "O"
                        ? generoMapping[data.genero] || data.genero
                        : "";
                console.log("idClientesEmpresas");
                console.log(idClientesEmpresas[0].id);

                if (data.email == "" || data.email == undefined) {
                    data.email = "";
                }

                if (data.pais == "" || data.pais == undefined) {
                    datos.pais = 1;
                    setMostrarMensajePais(true);
                }

                const newData = {
                    ...data,
                    genero: generoFormatted,
                    email_confirmacion: data.email,
                    rut: `${data.rut ? data.rut : ""}-${
                        !data.dv && data.dv != 0 ? "" : data.dv
                    }`,
                    telefono: data.telefono ? String(data.telefono) : "",
                    celular: data.celular
                        ? String(data.celular)
                              .replace(/\s/g, "")
                              .replace(/^0/, "")
                        : "",
                    plan: idPlanModal == "" ? data.plan : idPlanModal,
                    clienteEmp: data.empresa,
                };
                console.log("ESTO VIENNE EN EMRPESA", data.empresa);
                console.log("ESTO VIENNE EN PAIS ", data.pais);
                console.log("ESTO VIENNE EN data", data);

                if (data.empresa == "" || data.empresa == undefined) {
                    setMostrarMensajeEmpresa(true);
                }

                //setValue("rut", `${data.rut ? data.rut : ""}-${!data.dv && data.dv != 0? "" : data.dv}`);
                reset(newData);
                setDatos(newData);
            }

            if (razon) {
                console.log("Mensaje de razón:", razon);
            }
            setTieneValorModal(true);
            setAsignarModalData(true);
        }
    }, [
        modalData,
        reset,
        setDatos,
        setValue,
        setAsignarModalData,
        asignarModalData,
    ]);

    let listPlanes = planes.map((p) => (
        <option key={p.idPlan} value={p.idPlan} sku={p.sku}>
            {p.nombre}
        </option>
    ));

    let listPaises = paises.map((p) => (
        <option key={p.idPais} value={p.idPais} sku={p.codPais}>
            {p.nombre}
        </option>
    ));

    if (checked) {
        listPaises = listPaises.filter((p) => p.key !== "1");
    }

    let listGenero = generos.map((g) => (
        <option key={g.idGenero} value={g.idGenero}>
            {g.nombre}
        </option>
    ));
    let listClienteEmpresa = idClientesEmpresas.map((c) => (
        <option key={c.id} value={c.id}>
            {c.nombre_convenio}
        </option>
    ));

    return (
        <div ref={ref}>
            <Card sx={{ minWidth: 275, padding: 1 }}>
                <ListItemText primary="Datos de beneficiario" />
                <Divider light />
                <form
                    onSubmit={handleSubmit(onSubmitDatosBeneficario)}
                    autoComplete="false"
                    className="formularioSale"
                >
                    {mostrarMensajePais && (
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <span style={{ marginLeft: "5px" }}>
                                Beneficiario seleccionado no tiene un país asignado
                                en el documento y se usará Chile por defecto.
                            </span>
                        </div>
                    )}
                    {mostrarMensajeEmpresa && (
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <span style={{ marginLeft: "5px" }}>
                                Beneficiario seleccionado no tiene asignada una
                                Empresa y no puede ser creado. Favor editar
                                documento y volver a intentar.
                            </span>
                        </div>
                    )}

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <Switch
                            checked={checked}
                            onChange={handleChangeNacionalidad}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                        <span style={{ marginLeft: "5px" }}>
                            Beneficiario internacional
                        </span>
                    </div>

                    {Array.isArray(idClientesEmpresas) &&
                        idClientesEmpresas.length > 1 && (
                            <>
                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                                    <Box
                                        sx={{
                                            "& > :not(style)": { m: 1 },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <InputSelect
                                            register={register}
                                            descripcion="Cliente Empresa"
                                            rules={{
                                                required: "campo obligatorio",
                                            }}
                                            id="clienteEmp"
                                            name="clienteEmp"
                                            option="Seleccione Cliente Empresa"
                                            options={listClienteEmpresa}
                                            errors={errors.clienteEmp}
                                            value={datos.clienteEmp}
                                            onChange={HandleInputChange}
                                            infoPlan={datos.clienteEmp}
                                        />
                                    </Box>
                                </div>
                            </>
                        )}
                    {checked ? (
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                            <Box
                                sx={{
                                    "& > :not(style)": { m: 1 },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <InputSelect
                                    register={register}
                                    descripcion="Seleccione país"
                                    rules={{
                                        required: "campo obligatorio",
                                    }}
                                    id="pais"
                                    name="pais"
                                    option="Seleccione un país"
                                    options={listPaises}
                                    errors={errors.pais}
                                    value={datos.pais}
                                    onChange={HandleInputChange}
                                    infoPlan={datos.plan}
                                />
                            </Box>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            {checked ? (
                                <InputDni
                                    descripcion="DNI"
                                    register={register}
                                    name="dni"
                                    errors={errors}
                                    onChange={HandleInputChange}
                                    maxLength={10}
                                    datos={datos}
                                    value={datos.dni}
                                    tieneValorModal={tieneValorModal}
                                    setTieneValorModal={setTieneValorModal}
                                    idPlan={datos.plan}
                                    idPais={datos.pais}
                                />
                            ) : (
                                <InputRut
                                    descripcion="Rut"
                                    register={register}
                                    name="rut"
                                    errors={errors}
                                    onChange={HandleInputChange}
                                    maxLength={10}
                                    datos={datos}
                                    value={datos.rut}
                                    tieneValorModal={tieneValorModal}
                                    setTieneValorModal={setTieneValorModal}
                                    idPlan={datos.plan}
                                />
                            )}
                        </Box>
                    </div>

                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputSelect
                                register={register}
                                descripcion="Seleccione plan a ofrecer"
                                rules={{
                                    required: "campo obligatorio",
                                }}
                                id="plan"
                                name="plan"
                                option="Seleccione un plan"
                                options={listPlanes}
                                errors={errors.plan}
                                value={datos.plan}
                                onChange={HandleInputChange}
                                infoPlan={datos.plan}
                            />
                        </Box>
                    </div>

                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputText
                                register={register}
                                descripcion="Nombres"
                                rules={{
                                    required: "Campo obligatorio",
                                    validate: (v) => {
                                        const stringValue = String(v).trim();
                                        if (
                                            !/^[A-Za-zÀ-ú\s]+$/.test(
                                                stringValue
                                            )
                                        ) {
                                            return "Formato incorrecto";
                                        }
                                        return true;
                                    },
                                }}
                                type="text"
                                placeholder="Ingrese Nombre"
                                name="nombres"
                                errors={errors.nombres}
                                onChange={HandleInputChange}
                                onKeyPress={(event) => {
                                    if (!/[A-zÀ-ú\s]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                maxLength={150}
                                value={datos.nombres}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputText
                                register={register}
                                descripcion="Primer Apellido"
                                rules={{
                                    required: "Campo obligatorio",
                                    validate: (v) => {
                                        const stringValue = String(v).trim();
                                        if (
                                            !/^[A-Za-zÀ-ú\s]+$/.test(
                                                stringValue
                                            )
                                        ) {
                                            return "Formato incorrecto";
                                        }
                                        return true;
                                    },
                                }}
                                type="text"
                                placeholder="Ingrese primer apellido"
                                name="apellidoPaterno"
                                errors={errors.apellidoPaterno}
                                onChange={HandleInputChange}
                                onKeyPress={(event) => {
                                    if (!/[A-zÀ-ú\s]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                value={datos.apellidoPaterno}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputText
                                register={register}
                                descripcion="Segundo Apellido"
                                rules={{
                                    required: "Campo obligatorio",
                                    validate: (v) => {
                                        const stringValue = String(v).trim();
                                        if (
                                            !/^[A-Za-zÀ-ú\s]+$/.test(
                                                stringValue
                                            )
                                        ) {
                                            return "Formato incorrecto";
                                        }
                                        return true;
                                    },
                                }}
                                type="text"
                                placeholder="Ingrese segundo apellido"
                                name="apellidoMaterno"
                                errors={errors.apellidoMaterno}
                                onChange={HandleInputChange}
                                onKeyPress={(event) => {
                                    if (!/[A-zÀ-ú\s]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                value={datos.apellidoMaterno}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputEmail
                                register={register}
                                name="email"
                                errors={errors}
                                onChange={HandleInputChange}
                                datos={datos}
                                clearErrors={clearErrors}
                                descripcion="Email(opcional)"
                                value={datos.email}
                                required={true}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputEmail
                                register={register}
                                name="email_confirmacion"
                                errors={errors}
                                onChange={HandleInputChange}
                                datos={datos}
                                confirmar={true}
                                clearErrors={clearErrors}
                                onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                descripcion="Confirmar Email(opcional)"
                                value={datos.email_confirmacion}
                                required={true}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputText
                                register={register}
                                descripcion="Celular"
                                rules={{
                                    required: "campo obligatorio",
                                    validate: (v) =>
                                        v.length < 15 ||
                                        "número de celular incorrecto",
                                }}
                                type="text"
                                placeholder="Ingrese número de celular"
                                name="celular"
                                errors={errors.celular}
                                onChange={HandleInputChange}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                value={datos.celular}
                                maxLength={11}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputDate
                                register={register}
                                descripcion="Fecha de Nacimiento"
                                rules={{
                                    required: "Campo obligatorio",
                                    validate: (v) => {
                                        const isValidDate = !isNaN(
                                            Date.parse(v)
                                        );
                                        return (
                                            isValidDate ||
                                            "Formato de fecha incorrecto"
                                        );
                                    },
                                }}
                                type="date"
                                name="fechaNacimiento"
                                errors={errors["fechaNacimiento"]}
                                onChange={HandleInputChange}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputSelect
                                register={register}
                                descripcion="Género"
                                rules={{
                                    required: "campo obligatorio",
                                }}
                                id="genero"
                                name="genero"
                                option="Seleccione un género"
                                options={listGenero}
                                errors={errors.genero}
                                value={datos.genero}
                                onChange={HandleInputChange}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputText
                                register={register}
                                descripcion="Teléfono(opcional)"
                                rules={{
                                    validate: (v) =>
                                        v.length < 15 ||
                                        "número de teléfono incorrecto",
                                }}
                                type="text"
                                placeholder="Ingrese teléfono"
                                name="telefono"
                                errors={errors.telefono}
                                onChange={HandleInputChange}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                value={datos.telefono}
                                maxLength={11}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            {checked ? (
                                <InputDni
                                    descripcion="Titular(opcional)"
                                    register={register}
                                    name="titular"
                                    errors={errors}
                                    onChange={HandleInputChange}
                                    maxLength={10}
                                    datos={datos}
                                    value={datos.titular}
                                    titular={true}
                                    tieneValorModal={tieneValorModal}
                                    setTieneValorModal={setTieneValorModal}
                                    opcional={true}
                                    idPlan={datos.plan}
                                    idPais={datos.pais}
                                />
                            ) : (
                                <InputRut
                                    descripcion="Titular(opcional)"
                                    register={register}
                                    name="titular"
                                    errors={errors}
                                    onChange={HandleInputChange}
                                    maxLength={10}
                                    datos={datos}
                                    value={datos.titular}
                                    titular={true}
                                    tieneValorModal={tieneValorModal}
                                    setTieneValorModal={setTieneValorModal}
                                    opcional={true}
                                />
                            )}
                        </Box>
                    </div>
                    {!mostrarMensajeEmpresa && (
                        <div className="col-md-12 btn-acciones">
                            <Box
                                sx={{
                                    "& > :not(style)": { m: 1 },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="info"
                                    tabIndex={0}
                                >
                                    Registrar beneficiario
                                </Button>
                            </Box>
                        </div>
                    )}
                </form>
            </Card>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
});
