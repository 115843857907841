import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
//import reportWebVitals from './reportWebVitals';
import { RouterProvider } from "react-router-dom";
import "./bootstrap.scss";

import { DirectusProvider } from "./Providers/DirectusProvider";
import { Login } from "./Components/Login/Login";
import { router } from "./App";

/*const ROOT_ID = "root-componentes-react";*/
const URL = window.BACKEND_COMPONENTES_SEGURO || "https://seguros.ticmedical.cl/";

const componentes = Object.assign(
    {},
    ...Object.entries({
        Login,
    }).map(([name, x]) => ({
        [name]: (props) => React.createElement(x, props),
    }))
);

let agregarComponente = (nombre, id, props) => {
    const ev = new CustomEvent("agregar_componente", {
        detail: { nombre, id, props },
    });
    document.dispatchEvent(ev);
};

let root = document.getElementById("componente-show");

/*if (!root) {
  root = document.createElement("div");
  root.id = ROOT_ID;
  root.style.display = "none";
  document.body.appendChild(root);
}*/

ReactDOM.render(
    <DirectusProvider url={URL}>
        <>
            <RouterProvider router={router} />
        </>
    </DirectusProvider>,
    root
);

window.componentes_seguro = {};
for (let componente in componentes) {
    window.componentes_seguro[componente] = (id, props = {}) =>
        agregarComponente(componente, id, props);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
