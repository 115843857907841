import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { saveAs } from "file-saver";
import { write, utils } from "xlsx";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Swal from "sweetalert2";
import Moment from "moment";
import "./AsistenteVenta.css";
import MUIDataTable from "mui-datatables";

import { Opciones } from "./Opciones";

import { useDirectus } from "../../Providers/DirectusProvider";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#4d94ff",
        color: theme.palette.common.white,
        fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const rutCompleto = (rut) => {
    let rutLimpio = rut?.replace(/[^(0-9|k)]/gi, "");
    let M = 0,
        S = 1;
    let T = rutLimpio;
    for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
    var dv = S ? S - 1 : "k";
    return rutLimpio + "-" + dv;
};

export const AsistenteVentas = (props) => {
    /*LOADING*/
    const [openLoading, setOpenLoading] = React.useState(false);
    const handleCloseLoading = () => {
        setOpenLoading(false);
    };
    const handleToggleLoading = () => {
        setOpenLoading(!openLoading);
    };

    const userLogin = JSON.parse(localStorage.getItem("userLogin"));
    const idVendedor = JSON.parse(localStorage.getItem("idVendedor"));
    const { state } = useLocation();
    const { success } = state ? state : false; // Read values passed on state
    const [isLoading, setLoading] = useState(true);
    var begin = Moment().subtract(30, "days").format("YYYY-MM-DD");

    const { directus } = useDirectus();
    const [allSales, setAllSales] = useState([{}]);

    const columns = [
        {
            name: "idVendedor.nombre",
            label: "Vendedor",
        },
        {
            name: "fechaVenta",
            label: "Fecha Venta",
            options: {
                customBodyRender: (value, tableMeta, updateValue) =>
                    value ? Moment(value).format("YYYY-MM-DD") : "",
                filterType: "dropdown",
            },
        },
        {
            name: "estado",
            label: "Estado",
            options: {
                customBodyRender: (value, tableMeta, updateValue) =>
                    value === "1" ? "TERMINADO" : "EN PROCESO",
                filter: true,
                customFilterListOptions: { render: (v) => `${v}` },
                filterOptions: {
                    names: ["TERMINADO", "EN PROCESO"],
                },
            },
        },
        {
            name: "idBeneficiarioPlan.idBeneficiario.rut",
            label: "Rut Cliente",
            options: {
                customBodyRender: (value, tableMeta, updateValue) =>
                    value ? rutCompleto(value) : "",
                filterType: "dropdown",
            },
        },
        {
            name: "idBeneficiarioPlan.fechaVencimientoCobertura",
            label: "Vencimiento",
            options: {
                customBodyRender: (value, tableMeta, updateValue) =>
                    value ? Moment(value).format("YYYY-MM-DD") : "",
                filterType: "dropdown",
            },
        },
        {
            name: "idBeneficiarioPlan.idBeneficiarioPlan",
            label: "Opciones",
            download: false,

            options: {
                sortCompare: (order) => {
                    return ({ allSales: a }, { allSales: b }) => {
                        return (
                            (parseInt(a) - parseInt(b)) *
                            (order === "asc" ? 1 : -1)
                        );
                    };
                },
                customBodyRender: (value, meta) => {
                    const record =
                        allSales[meta.currentTableData[meta.rowIndex].index];
                    return (
                        <Opciones
                            key={meta.currentTableData[meta.rowIndex].index}
                            idBeneficiarioPlan={
                                record.idBeneficiarioPlan.idBeneficiarioPlan
                            }
                            handleCloseLoading={handleCloseLoading}
                            handleToggleLoading={handleToggleLoading}
                        />
                    );
                },
                filter: false,
                print: false,
            },
        },
    ];
    const options = {
        textLabels: {
            body: {
                noMatch: "No se encontró información",
                toolTip: "Ordenar",
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "fila por página:",
                displayRows: "de", // 1-10 of 30
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar Excel",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtrar tabla",
            },
            filter: {
                all: "Todo",
                title: "FILTROS",
                reset: "por defecto",
            },
            viewColumns: {
                title: "Mostrar columnas",
            },
            selectedRows: {
                text: "fila(s) seleccionada",
            },
        },
        filterType: "checkbox",
        /*responsive: "scroll",*/
        enableNestedDataAccess: ".",
        sortOrder: {
            name: "fechaVenta",
            direction: "desc",
        },
        customToolbarSelect: () => {},
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            const fileExtension = ".xlsx";
            const json = values.reduce((result, val) => {
                const temp = {};
                val.data.forEach((v, idx) => {
                    if (columns[idx].label !== "Opciones") {
                        if (
                            columns[idx].label === "Fecha Venta" ||
                            columns[idx].label === "Vencimiento"
                        ) {
                            temp[columns[idx].label] =
                                Moment(v).format("YYYY-MM-DD");
                        } else if (columns[idx].label === "Rut Cliente") {
                            temp[columns[idx].label] = rutCompleto(v);
                        } else if (columns[idx].label === "Estado") {
                            temp[columns[idx].label] =
                                v === "1" ? "TERMINADO" : "EN PROCESO";
                        } else {
                            temp[columns[idx].label] = v;
                        }
                    }
                });
                result.push(temp);
                return result;
            }, []);

            const fileName = `Listado Ventas_` + Moment().format("YYYY-MM-DD");
            const ws = utils.json_to_sheet(json);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            saveAs(data, fileName + fileExtension);
            // cancel default  CSV download from table
            return false;
        },
        selectableRows: "none",
        expandableRows: true,
        renderExpandableRow: (rowData, rowMeta) => {
            const dataExtra = allSales[rowMeta.dataIndex];
            const nombre =
                dataExtra.idBeneficiarioPlan.idBeneficiario.nombres +
                " " +
                dataExtra.idBeneficiarioPlan.idBeneficiario.primerApellido +
                " " +
                dataExtra.idBeneficiarioPlan.idBeneficiario.segundoApellido;
            const telefono = dataExtra.idBeneficiarioPlan.idBeneficiario
                .telefono
                ? dataExtra.idBeneficiarioPlan.idBeneficiario.telefono
                : "Sin info";
            const celular = dataExtra.idBeneficiarioPlan.idBeneficiario.celular
                ? dataExtra.idBeneficiarioPlan.idBeneficiario.celular
                : "Sin info";
            const telefonoCelular = telefono + " / " + celular;
            const email = dataExtra.idBeneficiarioPlan.idBeneficiario.email;
            const plan = dataExtra.idBeneficiarioPlan.idPlan.nombre;

            return (
                <TableRow sx={{ margin: 0, paddingBottom: 0, paddingTop: 0 }}>
                    <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={7}
                    >
                        <Box
                            sx={{ margin: 0, paddingBottom: 0, paddingTop: 0 }}
                        >
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">
                                            Nombre Beneficiario
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            Teléfono / Celular
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            Email
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            Plan
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow key={rowMeta.rowIndex}>
                                        <StyledTableCell align="left">
                                            {nombre}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {telefonoCelular}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {email}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {plan}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </TableCell>
                </TableRow>
            );
        },
    };
    //CONSULTAR VENTAS
    if (success) {
        Swal.fire({
            title: "Beneficiario registrado correctamente",
            text: "Ahora el beneficiario solo debe abrir el correo y seguir las instrucciones para completar el formulario",
            icon: "success",
        });
    }

    useEffect(() => {
        async function getAllSales() {
            if (userLogin.role.name === "admin_vendedor") {
                await directus
                    .items("venta")
                    .readByQuery({
                        fields: [
                            "estado",
                            "comentario",
                            "idVendedor.nombre",
                            "idVendedor.idVendedor",
                            "fechaVenta",
                            "idBeneficiarioPlan.idBeneficiario.*",
                            "idBeneficiarioPlan.*.*",
                            "idVenta",
                        ],
                        filter: {
                            fechaVenta: {
                                _gte: begin,
                            },
                            idBeneficiarioPlan: {
                                _nnull: true,
                            },
                            idVendedor: {
                                _nnull: true,
                            },
                        },
                        limit: -1,
                        sort: ["fechaVenta"],
                    })
                    .then(async (response) => {
                        setAllSales(response.data);
                        setLoading(false);
                    });
            } else if (userLogin.role.name === "vendedor") {
                await directus
                    .items("venta")
                    .readByQuery({
                        fields: [
                            "estado",
                            "comentario",
                            "idVendedor.nombre",
                            "idVendedor.idVendedor",
                            "fechaVenta",
                            "idBeneficiarioPlan.idBeneficiario.*",
                            "idBeneficiarioPlan.*.*",
                            "idVenta",
                        ],
                        filter: {
                            fechaVenta: {
                                _gte: begin,
                            },
                            idVendedor: idVendedor,
                        },
                        limit: -1,
                    })
                    .then(async (response) => {
                        setAllSales(response.data);
                        setLoading(false);
                    });
            }
        }
        getAllSales();
    }, [directus, success, begin, idVendedor, userLogin.role.name]);

    if (isLoading) {
        return <div className="App">Loading...</div>;
    }
    return (
        <div className="container-page">
            <Card sx={{ minWidth: 275, padding: 1 }}>
                <ListItemText primary="Seleccione tipo de venta" />
                <Divider light />
                <div className="dataCardOptions">
                    <Card
                        className="cartaInvididuales"
                        sx={{
                            minWidth: 210,
                            maxWidth: 200,
                            maxHeight: 120,
                            padding: 1,
                            marginTop: 1,
                            marginRight: 3,
                            backgroundColor: "#8bc34a",
                        }}
                    >
                        <CardContent
                            className="contenidoCard"
                            sx={{ padding: 1, textDecoration: "none" }}
                            component={Link}
                            to={"/nueva-venta"}
                        >
                            <Box
                                className="dataCardSell"
                                component="span"
                                sx={{}}
                            >
                                <div>
                                    <div className="">
                                        <PersonIcon
                                            className="bigIcon"
                                            sx={{ color: "white" }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="dataCardSellAction">
                                        <div className="textIndividualesDiv">
                                            <ListItemText
                                                className="textIndividuales"
                                                primary="Nueva venta remota"
                                            />
                                        </div>
                                        <IconButton color="primary">
                                            <ArrowCircleRightIcon className="arrowGreen" />
                                        </IconButton>
                                    </div>
                                </div>
                            </Box>
                        </CardContent>
                    </Card>
                    <Card
                        className="cartaInvididualesCotizacion"
                        sx={{
                            minWidth: 210,
                            maxWidth: 200,
                            maxHeight: 120,
                            padding: 1,
                            marginTop: 1,
                            backgroundColor: "#1976d2",
                        }}
                    >
                        <CardContent
                            className="contenidoCard"
                            sx={{ padding: 1, textDecoration: "none" }}
                            component={Link}
                            to={"/nueva-cotizacion"}
                        >
                            <Box
                                className="dataCardSell"
                                component="span"
                                sx={{}}
                            >
                                <div>
                                    <div className="">
                                        <PersonIcon
                                            className="bigIcon"
                                            sx={{ color: "white" }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="dataCardSellAction">
                                        <div className="textIndividualesDiv">
                                            <ListItemText
                                                className="textIndividuales"
                                                primary="Nueva cotización"
                                            />
                                        </div>
                                        <IconButton color="primary">
                                            <ArrowCircleRightIcon className="arrowBlue" />
                                        </IconButton>
                                    </div>
                                </div>
                            </Box>
                        </CardContent>
                    </Card>
                </div>
            </Card>
            <br />
            <Card
                className="p-0 contenedorTabla"
                sx={{ minWidth: 275, padding: 1 }}
            >
                <MUIDataTable
                    title={"Ventas remotas"}
                    data={allSales}
                    columns={columns}
                    options={options}
                />
            </Card>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};
