import { useOutletContext } from "react-router-dom";
import React, { useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import { useDirectus } from "../../Providers/DirectusProvider";
import { InputRut } from "./InputRut";
import { SalesForm } from "./SalesForm";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

export const InfoSale = (props) => {
    const dataPage = useOutletContext();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        setFocus,
    } = useForm({ mode: "all" });
    const { directus } = useDirectus();
    const [newClientRut, setNewClientRut] = useState("");
    const [mostrarFormulario, setMostrarFormulario] = useState("d-none");
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [planes, setPlanes] = useState([]);

    const onSubmit = (data) => checkRut(data);

    const checkRut = async (data) => {
        //lo pasamos a endpoint por seguridad
        var consulta = await directus.transport.get("/flow/check_rut", {
            params: { rut: data.rut },
        });
        if (!consulta.raw) {
            Swal.fire({
                title: "El rut que ha ingresado ya se encuentra registrado como beneficiario",
                icon: "error",
            });
            return consulta.raw;
        } else {
            setNewClientRut(data.rut);
            setBtnDisabled(true);
            //mostrar formulario
            Swal.fire({
                title: "Beneficiario disponible para suscripción",
                text: false,
                icon: "success",
            });
        }
    };

    //CONSULTAR BENEFICIARIO
    useEffect(() => {
        if (newClientRut != "") {
            setMostrarFormulario("");
        }
        async function getPlanes() {
            const res = await directus.items("plan").readByQuery({
                fields: ["*"],
                filter: {
                    idSeguro: 1,
                    activo: true,
                },
            });
            setPlanes(res.data);
        }
        getPlanes();
    }, [directus, newClientRut]);

    return (
        <div className="">
            <Card sx={{ minWidth: 275, padding: 1 }}>
                <ListItemText primary="Buscar Rut" />
                <Divider light />
                <form
                    className="row"
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete="false"
                >
                    <div className="d-flex">
                        <div className="p-8 w-100 mr-3">
                            <Box
                                sx={{
                                    "& > :not(style)": { m: 1 },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <InputRut
                                    checkingDisabled={true}
                                    disabled={btnDisabled}
                                    register={register}
                                    name="rut"
                                    errors={errors}
                                    maxLength={10}
                                    validarNuevaCarga={false}
                                />
                            </Box>
                        </div>
                        <div className="p-2 w-100">
                            <Stack spacing={2} direction="row">
                                <Button
                                    disabled={btnDisabled}
                                    type="submit"
                                    variant="contained"
                                >
                                    Consultar
                                </Button>
                            </Stack>
                        </div>
                    </div>
                </form>
            </Card>
            <br />
            <div className={mostrarFormulario}>
                <SalesForm
                    newClientRut={newClientRut}
                    btnDisabled={btnDisabled}
                    idVendedor={dataPage.id}
                />
            </div>
        </div>
    );
};
