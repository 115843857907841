import { useOutletContext } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { useDirectus } from "../../Providers/DirectusProvider";
import { InputRut } from "./InputRut";
import { QuoteForm } from "./QuoteForm";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

export const NewQuote = (props) => {
    const dataPage = useOutletContext();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "all" });
    const { directus } = useDirectus();
    const [newClientRut, setNewClientRut] = useState("");
    const [mostrarFormulario, setMostrarFormulario] = useState("d-none");
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [planes, setPlanes] = useState([]);

    /*LOADING*/
    const [openLoading, setOpenLoading] = React.useState(false);
    const handleCloseLoading = () => {
        setOpenLoading(false);
    };
    const handleToggleLoading = () => {
        setOpenLoading(!openLoading);
    };

    const onSubmit = (data) => checkRut(data);

    const checkRut = async (data) => {
        //lo pasamos a endpoint por seguridad
        var consulta = await directus.transport.get("/flow/check_rut", {
            params: { rut: data.rut },
        });
        if (!consulta.raw) {
            Swal.fire({
                title: "El rut que ha ingresado ya se encuentra registrado como beneficiario",
                icon: "error",
            });
            return consulta.raw;
        } else {
            setNewClientRut(data.rut);
            setBtnDisabled(true);
            //mostrar formulario
            Swal.fire({
                title: "Beneficiario disponible para suscripción",
                text: false,
                icon: "success",
            });
        }
    };


    //CONSULTAR BENEFICIARIO
    useEffect(() => {
        if (newClientRut != "") {
            setMostrarFormulario("");
        }
        async function getPlanes() {
            const res = await directus.items("plan").readByQuery({
                fields: ["*"],
                filter: {
                    idSeguro: 1,
                    activo: true,
                },
            });
            setPlanes(res.data);
        }
        getPlanes();
    }, [directus, newClientRut]);

    return (
        <div className="container-page">
            <div>
                <QuoteForm
                    newClientRut={newClientRut}
                    btnDisabled={btnDisabled}
                    idVendedor={dataPage.id}
                    handleCloseLoading={handleCloseLoading}
                    handleToggleLoading={handleToggleLoading}
                />
            </div>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};
