import React from "react";
import { InputText } from "./InputText";
import { InputSelect } from "./InputSelect";
import { InputDate } from "./InputDate";
import Box from "@mui/material/Box";

export const SalesFormExtended = ({
    id,
    register,
    errors,
    listGenero,
    reglaEdadMin,
    reglaEdadMax,
    datos,
    HandleInputChange,
    listRegion,
    checkComuna,
    listComuna,
    listCargas,
}) => {

    return (
        <>
            <div key={id} className="conteiner-form-extended">
                <hr></hr>
                <h5>Información Adicional del Titular</h5>
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputSelect
                                register={register}
                                descripcion="Género"
                                rules={{
                                    required: "campo obligatorio",
                                }}
                                id="genero"
                                name="genero"
                                option="Seleccione un genero"
                                options={listGenero}
                                errors={errors.genero}
                                value={datos.genero}
                                onChange={HandleInputChange}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputDate
                                register={register}
                                descripcion="Fecha de Nacimiento"
                                rules={{
                                    required: "campo obligatorio",
                                    validate: {
                                        menor: (v) =>
                                            reglaEdadMin(v) ||
                                            "La edad minima es 18 años",
                                        mayor: (v) =>
                                            reglaEdadMax(v) ||
                                            "La edad maxima es 65 años",
                                    },
                                }}
                                type="date"
                                name={"fechaNacimiento"}
                                errors={errors["fechaNacimiento"]}
                                onChange={HandleInputChange}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputSelect
                                register={register}
                                descripcion="Región"
                                rules={{
                                    required: "campo obligatorio",
                                }}
                                id="region"
                                name="region"
                                option="Seleccione una región"
                                options={listRegion}
                                value={datos.region}
                                errors={errors.region}
                                onChange={HandleInputChange}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputSelect
                                register={register}
                                descripcion="Comuna"
                                rules={{
                                    required: "campo obligatorio",
                                    validate: {
                                        cc: (v) =>
                                            checkComuna(v) ||
                                            "campo obligatorio",
                                    },
                                }}
                                id="comuna"
                                name="comuna"
                                option="Seleccione una comuna"
                                options={listComuna}
                                value={datos.comuna}
                                errors={errors.comuna}
                                onChange={HandleInputChange}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputText
                                register={register}
                                descripcion="Dirección"
                                rules={{
                                    required: "campo obligatorio",
                                    validate: (v) =>
                                        !!v.trim() || "campo obligatorio",
                                }}
                                type="text"
                                placeholder="Ingrese su dirección"
                                name="direccion"
                                errors={errors.direccion}
                                onChange={HandleInputChange}
                                value={datos.direccion}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputText
                                register={register}
                                descripcion="Número domicilio"
                                rules={{
                                    required: "campo obligatorio",
                                }}
                                type="text"
                                placeholder="Ingrese número de domicilio"
                                name="nro_direccion"
                                value={datos.nro_direccion}
                                errors={errors.nro_direccion}
                                onChange={HandleInputChange}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputText
                                register={register}
                                descripcion="Depto/casa "
                                rules={{}}
                                type="text"
                                placeholder="Ingrese referencia"
                                name="referencia"
                                onChange={HandleInputChange}
                                noRequerido={true}
                                value={datos.referencia}
                            />
                        </Box>
                    </div>
                    <div className="col-md-12 p-3">
                        <h5>Información de cargas</h5>
                        <br />
                        <div>{listCargas}</div>
                    </div>
                </div>
            </div>
        </>
    );
};
